/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          textAlign: "center",
        }}
      >
        <main>{children}</main>
        <footer style={{ fontFamily: "monospace" }}>
          <p>
            <a
              href={"mailto:contact@iffycollector.com"}
              style={{ color: "#fff", textDecoration: "none", fontWeight: 900 }}
            >
              Contact
            </a>{" "}
            |{" "}
            <a
              style={{ color: "#fff", textDecoration: "none", fontWeight: 900 }}
              href="https://twitter.com/iffycollector?ref_src=twsrc%5Etfw"
              class="twitter-follow-button"
              data-show-count="false"
            >
              Follow @iffycollector
            </a>
          </p>
          <p>
            <a
              style={{ color: "#fff", textDecoration: "none", fontWeight: 900 }}
              href={"/privacy-policy"}
            >
              Privacy Policy
            </a>
          </p>
          © {new Date().getFullYear()}, iffy: Media Collector
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
